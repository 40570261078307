.chart-wrapper {
  @apply flex h-64 w-full items-center justify-center;
}

.chart-wrapper :global(.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child),
.chart-wrapper :global(.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child) {
  @apply hidden;
}

.chart-wrapper :global(.recharts-wrapper .recharts-pie-sector) {
  @apply outline-none;
}
