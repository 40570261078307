[aria-label='Sidebar']::-webkit-scrollbar {
  display: none;
}

[aria-label='Sidebar'] {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.content-wrapper::-webkit-scrollbar {
  display: none;
}

.content-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
