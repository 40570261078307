.skiptranslate,
#google-translate-element,
#goog-gt-tt {
  display: none !important;
}

font {
  box-shadow: none !important;
  background-color: transparent !important;
}
