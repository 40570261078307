.carousel-wrapper :global(.carousel) {
  @apply flex items-center justify-center;
}

.carousel-wrapper :global(.thumb) {
  @apply rounded-md p-0;
}

.carousel-wrapper :global(.thumbs-wrapper) {
  @apply m-0;
}

.carousel-wrapper :global(.thumb):hover,
.carousel-wrapper :global(.thumb.selected) {
  /* stylelint-disable-next-line max-line-length */
  @apply border-link after:bg-link relative border after:absolute after:left-0 after:top-0 after:h-full after:w-full after:bg-opacity-10 after:content-[''];
}
