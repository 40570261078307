/* stylelint-disable selector-class-pattern */
.accordion-control {
  @apply w-full pl-3 pr-12;
}

.accordion-control :global(.mantine-Accordion-label) {
  @apply py-3 pl-1 pr-0 text-sm;
}

.accordion-control :global(.mantine-Accordion-chevron) {
  @apply ml-2 mr-1;
}

.accordion-panel :global(.mantine-Accordion-content) {
  @apply p-0;
}

.accordion-control-inner {
  @apply w-full border-0 pl-4 pr-12;
}

.accordion-control-inner :global(.mantine-Accordion-label) {
  @apply py-2 text-sm;
}

.accordion-panel-inner :global(.mantine-Accordion-content) {
  @apply pb-1 pl-4;
}

.accordion-control-inner[data-accordion-control='true'] {
  @apply hover:bg-inherit;
}

.accordion-control-inner[aria-expanded='true'] :global(.mantine-Accordion-label) {
  @apply font-semibold;
}

.accordion-control-inner[aria-expanded='false'] {
  @apply hover:text-link;
}
