.button .icon {
  opacity: 0;
  color: #c5d0da;
}

.button {
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  background: none;

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}

.button[data-disabled='true'] {
  cursor: auto;
}

.button[data-active='true'] .icon {
  opacity: 1;
}

.button .icon[data-active='true'] {
  color: #27313b;
}

.button[data-align='left'] {
  justify-content: flex-end;
  flex-direction: row-reverse;
}

.button[data-align='right'] {
  justify-content: flex-end;
}

.icons {
  display: flex;
  flex-direction: column;

  svg {
    width: 1rem;
    height: 1rem;
  }
}
