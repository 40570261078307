.spinner {
  display: flex;
  height: 24px;
  flex-wrap: wrap;
  width: 24px;
}

.spinner > span {
  height: 50%;
  width: 50%;
}

@keyframes blue {
  0% {
    opacity: 0%;
    transform: translate(25%, 25%) scale(0.8);
  }

  25% {
    opacity: 0%;
    transform: translate(25%, 25%) scale(0.8);
  }

  50% {
    opacity: 100%;
    transform: translate(0, 0) scale(0.8);
  }

  75% {
    opacity: 0%;
    transform: translate(25%, 25%) scale(0.8);
  }

  100% {
    opacity: 0%;
    transform: translate(25%, 25%) scale(0.8);
  }
}

@keyframes yellow {
  0% {
    opacity: 0%;
    transform: translate(-25%, -25%) scale(0.8);
  }

  25% {
    opacity: 0%;
    transform: translate(-25%, -25%) scale(0.8);
  }

  50% {
    opacity: 100%;
    transform: translate(0, 0) scale(0.8);
  }

  75% {
    opacity: 0%;
    transform: translate(-25%, -25%) scale(0.8);
  }

  100% {
    opacity: 0%;
    transform: translate(-25%, -25%) scale(0.8);
  }
}

@keyframes red {
  0% {
    opacity: 0%;
    transform: translate(-25%, 25%);
  }

  35% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  70% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0%;
    transform: translate(-25%, 25%);
  }
}

@keyframes green {
  0% {
    opacity: 0%;
    transform: translate(25%, -25%);
  }

  30% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  60% {
    opacity: 100%;
    transform: translate(0, 0);
  }

  100% {
    opacity: 0%;
    transform: translate(25%, -25%);
  }
}

.spinner > span:nth-child(1) {
  animation: blue 1.25s ease infinite;
  background: #436cb0;
  border-radius: 50%;
  transform-origin: 0 0;
}

.spinner > span:nth-child(2) {
  animation: red 1.25s ease infinite;
  border-radius: 50% 50% 50% 0;
  background: #ed2f36;
  transform-origin: 100% 0;
}

.spinner > span:nth-child(3) {
  animation: green 1.25s ease infinite;
  border-radius: 50% 0 50% 50%;
  background: #4bad4e;
  transform-origin: 0 100%;
}

.spinner > span:nth-child(4) {
  animation: yellow 1.25s ease infinite;
  background: #f3c724;
  border-radius: 50%;
  transform-origin: 100% 100%;
}
